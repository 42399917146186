// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rt-image-error{width:100%;height:100%;background-color:#ccc;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#222}.rt-image-img{display:block;width:40px;height:40px;margin-bottom:5px}.rt-image-img svg{fill:#222}@-webkit-keyframes rt-image-spin{0%{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.rt-image-loading{width:25%;-webkit-animation:rt-image-spin 1.4s linear infinite;animation:rt-image-spin 1.4s linear infinite;-webkit-transform-origin:center center;transform-origin:center center}.rt-image-loading .rt-image-icon svg{fill:#222}", ""]);
// Exports
exports.locals = {
	"error": "rt-image-error",
	"img": "rt-image-img",
	"loading": "rt-image-loading",
	"spin": "rt-image-spin"
};
module.exports = exports;
